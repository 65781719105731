<template>
    <div class="gw_modal_wrap">
<!--        <div class="gw_title">{{ $t('thumbnail_background') }}</div>
        <div class="gw_upload_wrap" @click="[changeType('thumbnailFile'), initErrorMsg()]">
            <el-upload
                action=""
                list-type="picture-card"
                :file-list="thumbnailFile"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="onlyOnePhotoChange"
            >
                <button class="gw_thumbnail_box" v-if="thumbnailFile.length === 0" ></button>
                <button v-else class="gw_thumbnail_box" style="background-image: none;">
                    <img :src="thumbnailFile[0].org_url" v-if="thumbnailFile.length > 0" alt="">
                </button>


                &lt;!&ndash;                <img v-else src="@/assets/image/main/change_item.png" alt=""
                                     style="width: 93px; height: 74px;">&ndash;&gt;
            </el-upload>
            <div class="check_validator" v-if="validation.hasError('thumbnailFile')">
                {{ validation.firstError('thumbnailFile') }}
            </div>
        </div>-->
        <div class="gw_title">{{ $t('gallery_background') }}</div>
        <div class="gw_upload_wrap dis-block" @click="[changeType('backgroundFile'), initErrorMsg()]">
            <el-upload
                action=""
                list-type="picture-card"
                :file-list="backgroundFile"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="onlyOnePhotoChange"
            >
                <button class="gw_background_box" v-if="backgroundFile.length === 0"></button>
                <button v-else class="gw_background_box" style="background-image: none;">
                    <img :src="backgroundFile[0].org_url" v-if="backgroundFile.length > 0" alt="">
                </button>
            </el-upload>
            <div class="check_validator" v-if="validation.hasError('backgroundFile')">
                {{ validation.firstError('backgroundFile') }}
            </div>
        </div>
        <div class="gw_title">{{ $t('gallery_name') }}</div>
        <div class="gw_input">
            <el-input class="gw_input mb0" :placeholder="$t('gallery_input')" v-model="title" @input="parentChange('title', title)" maxLength="20"></el-input>
            <div class="check_validator" v-if="validation.hasError('title')">
                {{ validation.firstError('title') }}
            </div>
        </div>
        <div class="gw_btn_wrap">
            <button class="gw_btn delete" v-if="isGalleryData()" @click="openDeleteConfirm()">{{ $t('collection_delete') }}</button>
            <button class="gw_btn save" @click="runSave">{{ $t('save') }}</button>
            <div class="check_validator" v-if="errorMsg != ''">
                {{errorMsg}}
            </div>
        </div>

    </div>
</template>

<script>
import elFileMixins from "@/mixins/elFileMixins";
import galleryValidator from "@/mixins/validators/galleryValidator"
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

export default {
    name: "GalleryWriteModalLayout",
    mixins: [elFileMixins, galleryValidator],
    components: {
    },
    inject: ['galleryWriteSetData'],
    provide() {
        return{

        }
    },
    props: {
        galleryData: {default: () => {}},
    },
    data() {
        return{
            collectionName: '',
            errorMsg: '',
        }
    },
    beforeRouterEnter() {},
    created() {
        this.settingBeforeData();
    },
    mounted() {
        EventBus.$on('GalleryWriteModalLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('GalleryWriteModalLayoutValidator')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        settingBeforeData() {
            if(!this.isGalleryData()) {
                return false;
            }
            this.title = this.galleryData.c_title;
            this.galleryWriteSetData('title', this.title);
            if(!util.isEmpty(this.galleryData.Files) && !util.isEmpty(this.galleryData.Files.Thumb)) {
                this.thumbnailFile = this.galleryData.Files.Thumb.slice();
                this.galleryWriteSetData('thumbnailFile', this.thumbnailFile);
            }
            if(!util.isEmpty(this.galleryData.Files) && !util.isEmpty(this.galleryData.Files.Background)) {
                this.backgroundFile = this.galleryData.Files.Background.slice();
                this.galleryWriteSetData('backgroundFile', this.backgroundFile);
            }
        },
        runValidator(validatorArr=['title', 'thumbnailFile', 'backgroundFile']) {
            this.$validate(validatorArr).then(success => {
                if(success) {
                    this.errorMsg = "";
                }else{
                    this.errorMsg = this.$t('all_input');
                }
            })
        },
        parentChange(type, value) {
            this.galleryWriteSetData(type, value);
            this.initErrorMsg();
            this.$validate([type]).then(success => {
            });
        },
        initErrorMsg() {
            this.errorMsg = "";
        },
        runSave() {
            EventBus.$emit('writeGallery');
        },
        isGalleryData() {
            return !util.isEmpty(this.galleryData);
        },
        openDeleteConfirm() {
            this.createConfirm({
                content: `${this.$t('collection_delete_confirm1')}<br>${this.$t('collection_delete_confirm2')}`,
                confirm: () => {
                    EventBus.$emit('galleryWriteRunDelete')
                }
            })
        }
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
